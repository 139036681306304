import { FileType, Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

export enum DealerType {
  New = "new",
  Amp = "amp",
}

export const DealerTypeOptions: Array<OptionType<DealerType>> = [
  { value: DealerType.New, label: "Новые автомобили" },
  { value: DealerType.Amp, label: "Автомобили с пробегом" },
];

export interface DealerInterface {
  readonly id: number;
  readonly type: DealerType;
  readonly name: string;
  readonly image: string;
  readonly apiId: number;
  readonly brandIds: number[];
  readonly websiteUrl: string;
  readonly rating: number;
  readonly reviewsCount: number;
  readonly locationsCount: number;
  readonly canDelete: boolean;
}

export interface DealerFullInterface {
  readonly id: number;
  readonly type: DealerType;
  readonly name: string;
  readonly shortDescription: string;
  readonly urlSlug: string;
  readonly apiDealer: number;
  readonly brands: number[];
  readonly hideVin: boolean;
  readonly hidePrices: boolean;
  readonly legalName: string | null;
  readonly image: Nullable<FileType>;
  readonly imageCover: Nullable<FileType>;
  readonly pageTitle: string | null;
  readonly pageDescription: string | null;
  readonly seoText: string | null;
  readonly disablePartsOrder: boolean;
}
